
export default {
  name: 'Meta',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    content: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    time: null,
  }),
  mounted() {
    if (this.content) {
      this.time = this.$readingTime(this.content)
    }
  },
}
